import React from 'react';

import Page from "../Page";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFlask,
    faUserGraduate,
    faTimeline
} from '@fortawesome/free-solid-svg-icons'


export default class Consulting extends React.Component {
    render() {
        return (
            <Page>
                <h1 className={"title"}>Consulting</h1>
                <p className={"lead"}>
                    I offer a range of consulting services to help make your business use data science more effectively.
                    I am excited to work with businesses that are looking to use data to improve their products and services.
                </p>
                <div className={"row mt-2 mb-4"}>
                    <div className={"col-md-8"}>
                        <h3>Data analysis and consulting</h3>
                        <p>
                            We help businesses analyze their data and identify opportunities for improvement.
                            We use machine learning to identify patterns in data, build dashboards to track performance and develop reports to communicate findings to stakeholders.
                            We have a proven track record of helping businesses make better decisions with data.
                        </p>
                    </div>
                    <div className={"col-md-4 text-center"}>
                        <FontAwesomeIcon icon={faFlask} className='display-1' style={{color: '--primary'}}/>
                    </div>
                </div>
                <div className={"row mt-2 mb-4"}>
                    <div className={"col-md-4 text-center"}>
                        <FontAwesomeIcon icon={faUserGraduate} className='display-1' style={{color: '--primary'}}/>
                    </div>
                    <div className={"col-md-8"}>
                        <h3>Data science training</h3>
                        <p>
                            We offer training courses to help businesses learn how to use data science techniques and tools.
                            Our courses are taught by experienced data scientists who are passionate about teaching.
                            Our courses are designed to be practical and hands-on, so you can learn how to use data science to solve real-world problems.
                        </p>
                    </div>
                </div>
                <div className={"row mt-2 mb-4"}>
                    <div className={"col-md-8"}>
                        <h3>Data science project management</h3>
                        <p>
                            We help businesses manage data science projects from start to finish.
                            We have a proven track record of delivering successful data science projects.
                            We are experts in defining the project scope, setting goals, identifying resources and tracking progress.
                        </p>
                    </div>
                    <div className={"col-md-4 text-center"}>
                        <FontAwesomeIcon icon={faTimeline} className='display-1' style={{color: '--primary'}}/>
                    </div>
                </div>
            </Page>
        );
    }
}

