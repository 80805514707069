import React from 'react';

import NavBar from "./NavBar";
import './Page.css';

export default class Page extends React.Component {
    render() {
        const children = this.props.children;
        return (
            <div className="App">
                <NavBar onPageChange={this.props.onPageChange} />
                <div id="page-content" className={"container"}>
                    {children}
                </div>
            </div>
        );
    }
}

