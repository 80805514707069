import React from "react";

import './NavBar.css'
import {Link} from "react-router-dom";
import logo from "./logo.jpg";

export default class NavBar extends React.Component {
    render() {
        return (
            <nav className="navbar navbar-expand-md navbar-dark navbar-tbc d-print-none">
                <div className={'container-fluid'}>
                    <a className="navbar-brand mx-3 px-0" href="/">
                        <img className="img-fluid img-profile rounded-circle me-3" src={logo} alt="Thomas Bird"/>
                        thomasbird.com
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                                <Link to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/consulting">Consulting</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/cv">CV</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/physics">Physics</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/skydiving">Skydiving</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}