import React from 'react';
import TagManager from 'react-gtm-module'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

import Intro from "./components/Intro";
import Consulting from "./components/Consulting/Consulting";
import Resume from "./components/Resume/Resume";
import Physics from "./components/Physics/Physics";
import Skydiving from "./components/Skydiving/Skydiving";

const tagManagerArgs = {
    gtmId: 'GTM-NQCPKT5'
}

TagManager.initialize(tagManagerArgs)

export default class App extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/consulting">
                        <Consulting />
                    </Route>
                    <Route path="/cv">
                        <Resume />
                    </Route>
                    <Route path="/physics">
                        <Physics />
                    </Route>
                    <Route path="/skydiving">
                        <Skydiving />
                    </Route>
                    <Route path="/">
                        <Intro />
                    </Route>
                </Switch>
            </Router>
        );
    }
}

