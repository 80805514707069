import React from 'react';

import Page from "../Page";

import d2emu from './D2emu.png';
import dms from './dms.png';
import veloLayout from './velo_layout.png';

export default class Physics extends React.Component {
    render() {
        return (
            <Page>
                <h1 className={"title"}>Physics</h1>
                <p className={"lead"}>
                    I worked for 6 years in particle physics, mostly on the <a href={'https://en.wikipedia.org/wiki/LHCb_experiment'}>LHCb Experiment</a> at <a href={"https://en.wikipedia.org/wiki/CERN"}>CERN</a>.
                    Below are some of the highlights from my time there.
                </p>
                <div className={"row mt-2 mb-4"}>
                    <div className={"col-md-8"}>
                        <h3>Beautiful Mixing</h3>
                        <p>
                            There are two particles, the B<sup>0</sup> and B<sup>0</sup><sub>s</sub> that flip between matter and antimatter.
                            If these particles spent more time in the matter state, it could help to explain why our universe is made of matter instead of antimatter.
                        </p>
                        <p>
                            I measured that these particles flip between matter and antimatter around a trillion times per second.
                            They didn't prefer the matter or the antimatter state.
                            The first few oscillations of the B<sup>0</sup><sub>s</sub> particle can be seen in the graph to the right.
                        </p>
                        <blockquote>
                            The mixing frequencies Δm<sub>d</sub> and Δm<sub>s</sub> were measured using 1&nbsp;fb<sup>-1</sup> of semileptonic B decays and found to be 17.93&nbsp;±&nbsp;0.27&nbsp;ps<sup>-1</sup> and 0.503&nbsp;±&nbsp;0.017&nbsp;ps<sup>-1</sup>, respectively.
                            This was the first observation of Δm<sub>s</sub> in the semileptonic decay mode.
                            <cite>
                                <a href={"https://arxiv.org/abs/1308.1302"}>
                                    Link to paper
                                </a>
                            </cite>
                        </blockquote>
                    </div>
                    <div className={"col-md-4"}>
                        <img src={dms} style={{width: "100%"}} alt={"Oscillations of the B0 particle between matter and anti-matter."} title={"Shows the B0 particle oscillating between matter and anti-matter."}/>
                    </div>
                </div>
                <div className={"row mt-2 mb-4"}>
                    <div className={"col-md-4"}>
                        <img src={d2emu} style={{width: "100%"}} alt={"The measured mass of D0 particles."} title={"Shows the measured mass of D0 particles."} />
                    </div>
                    <div className={"col-md-8"}>
                        <h3>Charming Violations</h3>
                        <p>
                            Our theory of particle physics says that certain particles can't decay into other particles.
                            I checked to ensure that a particle called the D<sup>0</sup> can't decay into an electron and a muon together.
                            If it did decay in this way, it could indicate new and previously unseen physics.
                        </p>
                        <p>
                            I showed that this decay doesn't happen, but if it does happen, it happens in less than one in hundred million decays.
                            This helps to limit the types of new physics theories that are possible.
                        </p>
                        <blockquote>
                            The lepton flavour violating decay D<sup>0</sup>&nbsp;➝&nbsp;e<sup>±</sup>&nbsp;μ<sup>∓</sup> was searched for using 3&nbsp;fb<sup>-1</sup> of data.
                            A limit was set on the branching fraction, B(D<sup>0</sup>&nbsp;➝&nbsp;e<sup>±</sup>&nbsp;μ<sup>∓</sup>)&nbsp;&lt;&nbsp;1.3&nbsp;×&nbsp;10<sup>−8</sup>, at 90% confidence level. This is an order of magnitude lower than the previous limit.
                            <cite>
                                <a href={"https://arxiv.org/abs/1512.00322"}>
                                    Link to paper
                                </a>
                            </cite>
                        </blockquote>
                    </div>
                </div>
                <div className={"row mt-2 mb-4"}>
                    <div className={"col-md-8"}>
                        <h3>Velo Upgrade</h3>
                        <p>
                            The <a href={"https://lhcb-public.web.cern.ch/en/Detector/VELO-en.html"}>Velo</a> is a particle detector at the heart of the LHCb Experiment.
                            The Velo detects exactly where particles collide, but needs to be upgraded to cope with more simultaneous particle collisions.
                        </p>
                        <p>
                            I simulated and optimised the layout of the detector, to ensure that we could measure as many of the interesting particles as possible.
                            The image to the right shows a few of the layouts that were considered.
                            This detector will be installed in 2021.
                        </p>
                        <blockquote>
                            The upgraded VELO detector was simulated using the full-LHCb simulations.
                            The placement of the sensors along the z-axis was then optimised.
                            <cite>
                                <a href={"https://cds.cern.ch/record/1624070?ln=en"}>
                                    Link to paper
                                </a>
                            </cite>
                        </blockquote>
                    </div>
                    <div className={"col-md-4"}>
                        <img src={veloLayout} style={{width: "100%"}} alt={"Placement of detectors for three different possible designs."} title={"Shows placement of detectors for three different possible velo upgrade designs."}/>
                    </div>
                </div>
            </Page>
        );
    }
}

