import React from 'react';

import Page from "../Page";

import seville from './seville-jump.jpg';
import wellies from './skydive-wellies.jpg';
import sonia from './sonia.jpg';

export default class Skydiving extends React.Component {
    render() {
        return (
            <Page>
                <h1 className={"title"}>Skydiving</h1>
                <p className={"lead"}>
                    I've been an active skydiver for around 10 years and have over 450 jumps.
                </p>
                <p>
                    Here are a few photos of some older, but memorable jumps.
                </p>
                <div className={"row mt-5 mb-4 align-items-center justify-content-around"}>
                    <div className="col-md-4">
                        <div className="card">
                            <img src={seville} className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Skydiving Licence</h5>
                                <p className="card-text">
                                    Skydiving in Seville, where I got my first Skydiving licence.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card">
                            <img src={wellies} className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">So British</h5>
                                <p className="card-text">
                                    Skydiving in wellies, because the landing area was wet, wet, wet.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 ">
                        <div className="card">
                            <img src={sonia} className="card-img-top" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title">Brazilian Sunset</h5>
                                <p className="card-text">
                                    Sunset over Skydive Resende.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </Page>
        );
    }
}

