import React from "react";

import './ObfuscatedLink.css'

export default class ObfuscatedLink extends React.Component {

    cipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

        return text => text.split('')
            .map(textToChars)
            .map(applySaltToChar)
            .map(byteHex)
            .join('');
    }

    decipher = salt => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
        return encoded => encoded.match(/.{1,2}/g)
            .map(hex => parseInt(hex, 16))
            .map(applySaltToChar)
            .map(charCode => String.fromCharCode(charCode))
            .join('');
    }

    makeSalt(length) {
        var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
        var pass = "";
        for (var x = 0; x < length; x++) {
            var i = Math.floor(Math.random() * chars.length);
            pass += chars.charAt(i);
        }
        return pass;
    }

    constructor(props) {
        super(props);
        const salt = this.makeSalt(20);
        const cipherInstance = this.cipher(salt)
        this.state = {
            salt: salt,
            href: cipherInstance(props.href),
            children: cipherInstance(props.children),
            passed: false,
        };
        this.handleClick = this.handleClick.bind(this)
        this.componentDidMount = this.componentDidMount.bind(this)
    }

    componentDidMount() {
        const decipherInstance = this.decipher(this.state.salt)
        this.setState({
            children: decipherInstance(this.state.children).split('').reverse().join('\u200c')
        })
    }

    handleClick() {
        const decipherInstance = this.decipher(this.state.salt)
        var win = window.open(decipherInstance(this.state.href), '_blank');
        win.focus();
    }

    render() {
        return (
            // eslint-disable-next-line
            <a href='#' onClick={this.handleClick} onLoad={this.handleLoad}
               style={{direction: 'rtl', unicodeBidi: 'bidi-override'}} className={this.props.className}>
                {this.state.children}
            </a>
        )
    }
}