import React from 'react';
import Page from "../Page";
import ObfuscatedLink from "../ObfuscatedLink";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPhone,
    faGlobe,
    faEnvelope,
    faGraduationCap,
    faAtom,
    faMoneyBill,
    faDatabase,
    faDisease,
    faUsers, faIdCard
    // ,faParachuteBox, faArrowUp, faDice, faAnchor, faBroadcastTower
} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'

import './Resume.css'

export default class Resume extends React.Component {
    render() {
        return (
            <Page>
                <h1 className="mb-0 title">Thomas Bird <span className="small fw-normal">PhD</span></h1>
                <div className="mb-5 subtitle">Data Scientist in London</div>
                <p className="lead mb-4">
                    I'm a data scientist that works at the intersection of data science, software development and infrastructure engineering
                    to build systems that solve real problems and generate value.
                    I've experience across many industries, but most recently biotech.
                    My background is in Particle Physics, where I spent over 6 years the forefront of modern science,
                    using data science to study antimatter with the LHC at CERN. Now I focus on finding the value hidden in data.
                </p>

                {/*<div className='viewportsize'>*/}
                {/*    <span className='d-block d-sm-none'>xs</span>*/}
                {/*    <span className='d-none d-sm-block d-md-none'>sm</span>*/}
                {/*    <span className='d-none d-md-block d-lg-none'>md</span>*/}
                {/*    <span className='d-none d-lg-block d-xl-none'>lg</span>*/}
                {/*    <span className='d-none d-xl-block d-xxl-none'>xl</span>*/}
                {/*    <span className='d-none d-xxl-block'>xxl</span>*/}
                {/*</div>*/}



                    <div className="workplace highlightleft">
                        <div className={"row mb-2 mt-4"}>
                            <div className={"col-auto"}>
                                <FontAwesomeIcon icon={faDisease} className='display-3' style={{color: 'rgb(59 130 246)'}}/>
                            </div>
                            <div className={"col-auto"}>
                                <h3 className="mb-0">Data Science Lead</h3>
                                <div className="subheading">InvertBio</div>
                            </div>
                            <div className={"col-md-auto ms-auto col-print-auto"}>
                                <div className="resume-date text-md-end">
                                    <span className="text-primary">January 2022 - Present</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p>
                                Leading the data science strategy at a biotech startup that analyses data from cells growing in tanks
                                to improve both yields and quality of products such as egg whites, spider silk, or lab-grown meat.
                                Designing and building the infrastructure and software needed,
                                leveraging this to analyse data and determine information about the metabolism of cells.
                                Automating the process of scheduling the production of products to minimise wastage.
                            </p>
                        </div>
                    </div>


                <section className="resume-section">

                    <div className="workplace highlightleft">
                        <div className={"row mb-2 mt-4"}>
                            <div className={"col-auto"}>
                                <FontAwesomeIcon icon={faUsers} className='display-3 text-center' style={{color: '#afd122'}}/>
                            </div>
                            <div className={"col-auto"}>
                                <h3 className="mb-0">Senior Data Scientist</h3>
                                <div className="subheading">Leap Beyond Analytics</div>
                            </div>
                            <div className={"col-md-auto ms-auto col-print-auto"}>
                                <div className="resume-date text-md-end">
                                    <span className="text-primary">January 2018 - December 2021</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p>
                                Consultant across many industries, including medicine, law, finance, consumer goods and energy.
                                Experience leading cross-functional teams. Supporting and upskilling client engineering and data science teams.
                                One project created a cloud-based data science capability in a biotech company,
                                which allowed industrial processes to be optimised to produce more yield and provide millions of euros in savings.
                                Projects involved machine learning, natural language processing, computer vision and statistical modelling.
                                Contributions to multiple open-source python projects.
                            </p>
                        </div>
                    </div>


                    <div className="workplace highlightleft">
                        <div className={"row mb-2 mt-4"}>
                            <div className={"col-auto"}>
                                <FontAwesomeIcon icon={faDatabase} className='display-3' style={{color: '#FF6600'}}/>
                            </div>
                            <div className={"col-auto"}>
                                <h3 className="mb-0">Data Scientist</h3>
                                <div className="subheading">Think Big Analytics, a Teradata company</div>
                            </div>
                            <div className={"col-md-auto ms-auto col-print-auto"}>
                                <div className="resume-date text-md-end">
                                    <span className="text-primary">December 2016 - January 2018</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p>
                                Created a real-time scoring engine for potential customers in a regulated industry,
                                providing a significant competitive advantage.
                                Developed machine learning algorithms to predict diabetes complications,
                                helping to target patients better and save money.
                                Led the development of a data science environment that quickly deployed clusters to the cloud.
                            </p>
                        </div>
                    </div>



                    <div className="workplace highlightleft">
                        <div className={"row mb-2 mt-4"}>
                            <div className={"col-auto"}>
                                <FontAwesomeIcon icon={faMoneyBill} className='display-3' style={{color: '#00d9c3'}}/>
                            </div>
                            <div className={"col-auto"}>
                                <h3 className="mb-0">Data Scientist</h3>
                                <div className="subheading">Zopa</div>
                            </div>
                            <div className={"col-md-auto ms-auto col-print-auto"}>
                                <div className="resume-date text-md-end">
                                    <span className="text-primary">September 2015 - December 2016</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p>
                                Developed models to reduce the need for loan applicants to prove their income,
                                which resulted in an increase of loan disbursals by £2M per month.
                                Produced a website to monitor in near real-time the conversion rates of customers,
                                which resulted in increased loan disbursals by £400k per month.
                                Lead a strategy to offer personalised content to users using A/B tests.
                                Analysed TV and email advertising, determined their effectiveness, and optimised them.
                                Simulated loan repayments to determine the sale price of debt, given the repayment risks.
                            </p>
                        </div>
                    </div>


                    <div className="workplace highlightleft">
                        <div className={"row mb-2 mt-4"}>
                            <div className={"col-auto"}>
                                <FontAwesomeIcon icon={faAtom} className='display-3' style={{color: '#0033a0'}}/>
                            </div>
                            <div className={"col-auto"}>
                                <h3 className="mb-0">Particle Physics Research</h3>
                                <div className="subheading">CERN / University of Manchester</div>
                            </div>
                            <div className={"col-md-auto ms-auto col-print-auto"}>
                                <div className="resume-date text-md-end">
                                    <span className="text-primary">September 2010 - September 2015</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p>
                                I analysed the differences between matter and antimatter using machine learning and other statistical techniques.
                                This type of analysis is truly big data;
                                the LHCb experiment studies 40 million particle collisions per second and each collision results in around 100kB of data.
                                Due to the prohibitively large volume of data,
                                real-time algorithms are used select which collisions are kept for further analysis.
                                I also contributed to the design and simulation of a new detector that was installed in 2022.
                            </p>
                            {/*<ul>*/}
                            {/*    <li>*/}
                            {/*        Analysed the differences between matter and antimatter using machine learning and other statistical techniques.*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        This type of analysis is truly big data; the LHCb experiment studies 40 million particle collisions per second and each collision results in around 100kB of data.*/}
                            {/*        Due to the prohibitively large volume of data, real-time algorithms are used select which collisions are kept for further analysis.*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        Simulated and optimised the layout of a new particle detector that LHCb will use in 2023.*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        Primary author on 10 papers/proceedings/technical notes, on author list of 340 papers.*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                        </div>
                    </div>
                </section>

                <section className="resume-section" id="education">

                    <div className="workplace highlightleft">
                        <div className={"row"}>
                            <div className={"col-auto"}>
                                <h3 className="mb-0">
                                    <FontAwesomeIcon icon={faGraduationCap} style={{color: '#660099'}}/>&nbsp;
                                    University of Manchester
                                </h3>
                                {/*<div className="subheading"></div>*/}
                            </div>
                            <div className={"col-md-auto ms-auto col-print-auto"}>
                                <div className="resume-date text-md-end">
                                    <span className="text-primary">2011 - 2015</span>
                                </div>
                            </div>
                        </div>
                        <p>
                            PhD in Experimental Particle Physics. Included two years abroad at CERN in Geneva.
                        </p>
                    </div>

                    <div className="workplace highlightleft ">
                        <div className={"row"}>
                            <div className={"col-auto"}>
                                <h3 className="mb-0">
                                    <FontAwesomeIcon icon={faGraduationCap} style={{color: '#005084'}}/>&nbsp;
                                    University of Southampton
                                </h3>
                                {/*<div className="subheading"></div>*/}
                            </div>
                            <div className={"col-md-auto ms-auto col-print-auto"}>
                                <div className="resume-date text-md-end">
                                    <span className="text-primary">2007 - 2011</span>
                                </div>
                            </div>
                        </div>
                        <p>
                            Masters of Particle Physics, First Class with Honors. Included a year abroad at CERN in Geneva.
                        </p>
                    </div>
                </section>

                <section className="resume-section" id="contact">

                    <div className="workplace highlightleft">
                        <div className={"row"}>
                            <div className={"col-auto"}>
                                <h3 className="mb-0">
                                    <FontAwesomeIcon icon={faIdCard} className={'text-primary'}/>&nbsp;
                                    Contact
                                </h3>
                                {/*<div className="subheading"></div>*/}
                            </div>
                        </div>
                        <p>
                            Feel free to contact me using any of the links below.
                            <ul className="mx-0 mt-2 fa-ul">
                                <li>
                                    <FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;
                                    <ObfuscatedLink href="tel:+447752252649" className={"pl-1"}>+447752252649</ObfuscatedLink>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faEnvelope} />&nbsp;&nbsp;
                                    <ObfuscatedLink href="mailto:thomas@thomasbird.com" className={"pl-1"}>thomas@thomasbird.com</ObfuscatedLink>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faGlobe} />&nbsp;&nbsp;
                                    <a href="http://thomasbird.com/cv" className={"pl-1"}>thomasbird.com/cv</a>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faLinkedin} />&nbsp;&nbsp;
                                    <a href="https://www.linkedin.com/in/thomasmbird/" className={"pl-1"}>linkedin.com/in/thomasmbird</a>
                                </li>
                                <li>
                                    <FontAwesomeIcon icon={faGithub} />&nbsp;&nbsp;
                                    <a href="https://github.com/thomasbird" className={"pl-1"}>github.com/thomasbird</a>
                                </li>
                            </ul>
                        </p>
                    </div>
                </section>


                <section className="mt-5" id="about">
                    <div className="row">

                        {/*<div className="col-md-auto mx-auto col-print-3">*/}
                        {/*    <div className="card me-1">*/}
                        {/*        <div className="card-body">*/}
                        {/*            <h5 className="card-title">Contact</h5>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="col-md-auto mx-auto col-print-3">*/}
                        {/*    <div className="card me-1">*/}
                        {/*        <div className="card-body">*/}
                        {/*            <h5 className="card-title">Hobbies</h5>*/}
                        {/*            <ul className="mx-0 fa-ul">*/}
                        {/*                <li>*/}
                        {/*                    <FontAwesomeIcon icon={faParachuteBox} />&nbsp;*/}
                        {/*                    Skydiving*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <FontAwesomeIcon icon={faArrowUp} />&nbsp;*/}
                        {/*                    Climbing*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <FontAwesomeIcon icon={faDice} />&nbsp;*/}
                        {/*                    Board games*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <FontAwesomeIcon icon={faAnchor} />&nbsp;*/}
                        {/*                    Water sports*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <FontAwesomeIcon icon={faBroadcastTower} />&nbsp;*/}
                        {/*                    Amateur radio*/}
                        {/*                </li>*/}
                        {/*            </ul>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="col-md-auto mx-auto col-print-3">*/}
                        {/*    <div className="card me-1">*/}
                        {/*        <div className="card-body">*/}
                        {/*            <h5 className="card-title">Python contributions</h5>*/}
                        {/*            <ul className="mx-0 fa-ul">*/}
                        {/*                <li>*/}
                        {/*                    scrubadub (maintainer)*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    docdigger (creator)*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    NLTK*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    spaCy*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    pyap*/}
                        {/*                </li>*/}
                        {/*            </ul>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<div className="col-md-auto mx-auto col-print-3">*/}
                        {/*    <div className="card me-1">*/}
                        {/*        <div className="card-body">*/}
                        {/*            <h5 className="card-title">Worked in</h5>*/}
                        {/*            <ul className="mx-0 fa-ul">*/}
                        {/*                <li>*/}
                        {/*                    United Kingdom*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    Switzerland*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    France*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    Denmark*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    Netherlands*/}
                        {/*                </li>*/}
                        {/*            </ul>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}



                    </div>

                </section>
            </Page>
        );
    }
}

