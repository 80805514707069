import React from 'react';
import logo from './logo.jpg';
import { Link } from 'react-router-dom';

import './Intro.css'

export default class Intro extends React.Component {
    render() {
        return (
            <div className={""}>
                <div className={"row mx-0"}>
                    <div className={"col-md-6 px-0"}>
                        <div className={"intro intro-left d-flex align-items-center justify-content-center"}>
                            <nav className="navbar navbar-dark text-center d-block " id="sideNav">
                                <img className="img-fluid img-profile rounded-circle mx-auto mb-5" src={logo} alt="Thomas Bird" />
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/consulting">Consulting</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/cv">CV</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/physics">Physics</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/skydiving">Skydiving</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className={"col-md-6"}>
                        <div className={"intro intro-right d-flex align-items-center justify-content-center"}>
                            <div className={""}>
                                <h1 className={"title"}>
                                    <strong>Thomas Bird </strong>
                                    <small className={"fw-normal"}>PhD</small>
                                </h1>
                                <div className={"subtitle mb-4 h3 large"}>
                                    Data scientist in London
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
